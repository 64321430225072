// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage, getImage } from "gatsby-plugin-image"
import {
  ArrowNarrowRightIcon,
  BeakerIcon,
  ChevronRightIcon,
  PlusIcon,
  MinusIcon,
} from "@heroicons/react/solid"
import { TruckIcon } from "@heroicons/react/outline"
import { GatsbyImage } from "gatsby-plugin-image"
import { StoreContext } from "../context/store"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ShopSlider from "../components/toolkit/shop-slider"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const Shop = ({ data }) => {
  const { addVariantToCart, loading } = React.useContext(StoreContext)
  const shopdata = data.dataJson
  const image = getImage(shopdata.avatar)
  const images = {
    shop: getImage(data.shop),
    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),
  }

  const [quantity, setQuantity] = useState(1)

  const addToCart = e => {
    e.preventDefault()
    console.log(shopdata)
    addVariantToCart(shopdata.sid, quantity)
    console.log("toast")
    const notify = () => toast("Product has been added to the cart")
    notify()
  }

  return (
    <Layout>
      <Seo title="Home" />
      <>
        <section>
          <div className="bg-dusk ">
            <div className="container mx-auto">
              <div className=" pt-44">
                <div className="flex gap-2 items-center">
                  <Link to="/toolkit">
                    <div className="text-chalk cursor-pointer">Toolkit</div>
                  </Link>
                  <div>
                    <ChevronRightIcon className="w-3 text-chalk" />
                  </div>
                  <div className="text-mint">{shopdata.name}</div>
                </div>
              </div>
              <div className="py-20 gap-20 flex lg:flex-row flex-col justify-between">
                <div className="lg:w-3/6 w-full">
                  <GatsbyImage image={image} className="w-full" />
                </div>
                <div>
                  <div className=" text-chalk text-lg font-firs-regular">
                    Softmatter
                  </div>
                  <div className="text-mint text-4xl font-firs-medium my-3">
                    {shopdata.name}
                  </div>
                  <div className="text-mint text-3xl">${shopdata.price}</div>
                  <div className="max-w-lg text-chalk my-8">
                    {shopdata.description}
                  </div>
                  <div className="uppercase text-mint font-firs-medium text-lg mb-2">
                    quantity :{" "}
                  </div>
                  <div className="inline-block mb-4">
                    <div className="flex gap-4 border border-mint items-center text-mint">
                      <button
                        className="p-4 cursor-pointer hover:bg-mint/10 duration-200"
                        onClick={() => setQuantity(Math.max(1, quantity - 1))}
                      >
                        <MinusIcon className="w-3" />
                      </button>
                      <div className="select-none font-mono">{quantity}</div>
                      <button
                        className="p-4 cursor-pointer hover:bg-mint/10 duration-200"
                        onClick={() => setQuantity(quantity + 1)}
                      >
                        <PlusIcon className="w-3" />
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <button
                      onClick={addToCart}
                      className="bg-mint px-5 py-3 text-black uppercase select-none"
                    >
                      add to cart
                    </button>
                  </div>
                  <div className="grid grid-cols-2 justify-between gap-y-2 gap-x-8 mt-10 ">
                    <div className="flex gap-4 items-center ">
                      <TruckIcon className="w-8 text-mint mb-2" />
                      <div className="text-mint">Shipping Details</div>
                    </div>
                    <div>
                      <div className="text-mint flex items-center h-full">
                        Terms and Conditions
                      </div>
                    </div>
                    <div className="text-chalk text-xs max-w-[200px]">
                      <div>
                        Allow 7-14 days for shipping. If you require express
                        shipping, do contact us
                      </div>
                      <div className="my-3 bg-mint pb-[1px] w-full"></div>
                      <div className="text-chalk text-xs max-w-[200px]">
                        This product has shipping restrictions to Australia and
                        the UK.
                      </div>
                    </div>
                    <div className="text-salmon text-xs max-w-[200px]">
                      Customers are responsible for additional customs fees,
                      duties, and tax
                    </div>

                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          {shopdata.slug === "flexible-battery" ? (
            <div></div>
          ) : (
            <div className="bg-dusk">
              <div className="container mx-auto">
                <div className="pb-20 text-mint font-semibold">
                  <div className="text-3xl mb-10">What’s in the Kit?</div>
                  <div className="grid md:grid-cols-2 grid-cols-6   gap-8">

                    {/* if */}
                    {shopdata.slug === "sustainable-development-kit" ? (
                      <>
                        <div className="md:col-span-1 col-span-5 md:text-base text-sm">

                          <div>

                          </div>
                          <table class="table-fixed">
                            <thead>
                              <tr>
                                <th>
                                  <div className="text-left mb-3 font-firs-semibold ">
                                    Components{" "}
                                  </div>
                                </th>

                                <th>
                                  <div className="mb-3 font-firs-semibold ">Qty</div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Recycled polyester (PET bottle recycled)</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>Seacell (Ocean substance)</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>Tea dye (hybrid tea color and synthetic dye)</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>Mahogany dye (hybrid mahogany color and synthetic dye)</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>Bionic nylon material swatch (Ocean collagen peptide with regular nylon)</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>Biodegradable polyester, spandex jersey with wicking material (77% biodegradable polyester, 23% spandex)</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>Sea wool (recycled bottle + food waste from oyster shell)</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>1x Biodegradable polyester, spandex jersey with wicking material swatch (78% Biodegradable polyester, 22% Spandex)</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>1M paper yarn ribbon</td>
                                <td>1</td>
                              </tr>
                              <tr>
                                <td>3 types of recycled paper for packaging</td>
                                <td>1</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </>
                    ) : (
                      <>
                        <div className="md:col-span-1 col-span-8 md:text-base text-sm">
                          <div className="mb-3 font-firs-semibold ">
                            Components{" "}
                          </div>
                          <div>
                            {shopdata.components.map((item, index) => {
                              return <div>{item.title}</div>
                            })}
                          </div>
                        </div>
                        <div>
                          <div className="mb-3 font-firs-semibold ">Qty</div>
                          <div>
                            <div>
                              {shopdata.components.map((item, index) => {
                                return <div>{item.quantity}</div>
                              })}
                            </div>
                          </div>
                        </div>
                      </>


                    )
                    }




                  </div>
                </div>
              </div>
            </div>
          )}
        </section>

        {shopdata.sectionOne && (
          <section
            className={`${shopdata.slug === "flexible-battery" ? "hidden" : " block"
              }`}
          >
            <div className="bg-dusk">
              <div className="container mx-auto pb-20">
                {/* <div className="text-mint mb-2">
                  Technical Specifications and Test Instructions of the
                  Components
                </div> */}
                <div className="text-salmon mb-5">
                  [1] {shopdata.sectionOne.title}
                </div>
                <div className="text-mint text-sm mb-3">
                  Tech Specifications:
                </div>
                <div className="text-mint text-sm mb-10">
                  {shopdata.sectionOne.techSpecification}
                </div>

                {shopdata.slug === "Writable-Development-Kit" ? (
                  <section>
                    <div className="bg-dusk">
                      <div className="">
                        <div className="grid grid-cols-5 text-mint ">
                          <div className="  border-b-[1px] font-firs-semibold border-mint  b-4 pt-2 md:text-base text-xs">
                            Heating Panel
                          </div>
                          <div className="   border-b-[1px] font-firs-semibold border-mint pb-4 pt-2 md:text-base text-xs">
                            Swatch Size
                          </div>
                          <div className="  border-b-[1px] font-firs-semibold border-mint  b-4 pt-2 md:text-base text-xs">
                            Max Temperature
                          </div>
                          <div className="  border-b-[1px] font-firs-semibold border-mint  b-4 pt-2 md:text-base text-xs">
                            Recommended Heat zones per garment
                          </div>
                          <div className=" border-b-[1px] font-firs-semibold border-mint pb-4 pt-2 md:text-base text-xs">
                            Comments
                          </div>
                          <div className=" border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            Embroider
                          </div>
                          <div className=" border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            99mm x 99mm
                          </div>
                          <div className=" border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            {" > 50 C "}
                          </div>
                          <div className="row-span-4 md:text-base text-xs">
                            <div className="  py-3">4 zones</div>
                          </div>
                          <div className="row-span-4 md:text-base text-xs">
                            <div className="  py-3">
                              <div>
                                Can be paired with intelligent heating using
                                sensor-based feedback.
                              </div>
                              <div>
                                Temperature varies on the power supplied (36-50
                                C).
                              </div>
                              <div>Typical power: 10-15W.</div>
                              <div>
                                *Please note excessive heat (&gt;35 C) may
                                result in burns and injury.
                              </div>
                            </div>
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            Circular Knit
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            88mm x 101mm
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            {" > 50 C "}
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            Printed Heating
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            99mm x 99mm
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            {" > 50 C "}
                          </div>
                          <div className="   pb-4 pt-2 md:text-base text-xs">
                            Flat Knit
                          </div>
                          <div className="   pb-4 pt-2 md:text-base text-xs">
                            99mm x 99mm
                          </div>
                          <div className="   pb-4 pt-2 md:text-base text-xs">
                            {" > 50 C "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : shopdata.slug === "Sustainable-Development-Kit" ? (
                  <section>
                    <div className=" bg-dusk ">
                      <div className="grid grid-cols-3 text-mint  ">
                        <div className="  border-b-[1px] border-mint pb-4 pt-2 font-firs-semibold">
                          Type
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2 font-firs-semibold">
                          Component
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2 font-firs-semibold">
                          Further Information
                        </div>

                        <div className="row-span-2 border-b-[1px] border-mint pb-4 pt-2  ">
                          Flat Knit Swatch
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2">
                          Lenzing Modal
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2">
                          Stretchable Recycled Polyester
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2">
                          Lenzing Tencel
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2">
                          Recycled Polyester
                        </div>

                        <div className="   row-span-4 pb-4 pt-2">
                          Circular Knit Swatch
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2">
                          Room Temperature Dye
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2">
                          <div>40% savings in water consumption</div>
                          <div> 70% savings in energy consumption</div>
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2">
                          Recycled Nylon
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2">
                          Made of waste feedstocks such as old industrial
                          fishing nets, resulting in a reduced carbon footprint
                          and limits consumption of non-renewable resources
                        </div>
                        <div className="  border-b-[1px] border-mint pb-4 pt-2 ">
                          Recycled CDP
                        </div>
                        <div className="   border-b-[1px] border-mint pb-4 pt-2">
                          Recycled cationic dyeable polyester chips, which
                          reduces the amount of virgin polyester chips
                        </div>
                        <div className=" pb-4 pt-2 ">
                          Bio based anti-microbial finish
                        </div>
                        <div className=" pb-4 pt-2 ">
                          <div> Natural-based antimicrobial technology</div>
                          <div>Planet and People friendly</div>
                          <div>
                            Peppermint oil has anti-odor and low-toxicity
                            properties
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : shopdata.slug === "Data-Transmission-Development-kit" ? (
                  <section>
                    <div className=" bg-dusk  text-mint">
                      <div className="grid grid-cols-2   ">
                        <div className="font-firs-semibold py-3 border-b-[1px] border-mint pb-4 pt-2">
                          Type
                        </div>
                        <div className="font-firs-semibold py-3 border-b-[1px] border-mint pb-4 pt-2">
                          Application
                        </div>
                        <div className="row-span-4 py-3 border-b-[1px] border-mint pb-4 pt-2">
                          Mechanical Snap/ Magnetic connector
                        </div>
                        <div className=" py-3">
                          To transfer power, digital, and analog data signals.
                          E.g.
                        </div>
                        <div className=" py-3">
                          1. Transfer power from detachable battery modules to
                          heating elements embedded in wearable garments (7.4v,
                          ~3A)
                        </div>
                        <div className=" py-3">
                          2. Transfer analog raw ECG signals from electrodes to
                          detachable processing modules.
                        </div>
                        <div className=" py-3 border-b-[1px] border-mint pb-4 pt-2">
                          3. Transfer digital data signals with protocols such
                          as I2C to detachable modules.
                        </div>
                        <div className=" row-span-2 py-3 border-b-[1px] border-mint pb-4 pt-2">
                          JST Connectors
                        </div>
                        <div className="  py-3">
                          To transfer power and data within soft goods as well
                          as to external accessories. E.g.{" "}
                        </div>
                        <div className="  py-3 border-b-[1px] border-mint pb-4 pt-2">
                          1. Connect multiple sensors to brain node.
                        </div>
                        <div className=" row-span-3 py-3">USB Connectors</div>
                        <div className="  py-3">
                          To transfer digital data through USB protocols and
                          power (5v, 2A). E.g.
                        </div>
                        <div className="  py-3">
                          1. Transfer power to USB powered heating panels.
                        </div>
                        <div className="  py-3">
                          2. Transfer data from digital sensors to external
                          accessories.
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  <section>
                    <div className="bg-dusk">
                      <div className="">
                        <div className="grid grid-cols-4 text-mint ">
                          <div className="  border-b-[1px] font-firs-semibold border-mint  b-4 pt-2 md:text-base text-xs">
                            Parameter
                          </div>
                          <div className="   border-b-[1px] font-firs-semibold border-mint pb-4 pt-2 md:text-base text-xs">
                            Conductive Woven
                          </div>
                          <div className="  border-b-[1px] font-firs-semibold border-mint  b-4 pt-2 md:text-base text-xs">
                            Max Temperature
                          </div>
                          <div className="  border-b-[1px] font-firs-semibold border-mint  b-4 pt-2 md:text-base text-xs">
                            Silicone
                          </div>
                          <div className=" border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            AC Impedance
                          </div>
                          <div className=" border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            9.7Ω (+/- 0.5Ω)
                          </div>
                          <div className=" border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            12.3Ω (+/- 0.5Ω)
                          </div>
                          <div className=" border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            608Ω (+/- 5Ω)
                          </div>

                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            DC Offset Voltage
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            2.0mV (+/- 0.2mV)
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            2.0mV (+/- 0.2mV)
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            0.8mV (+/- 0.2mV)
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            Impedance after SDR
                          </div>
                          <div className="  border-b-[1px] border-mint pb-4 pt-2 md:text-base text-xs">
                            9.7Ω (+/- 0.5Ω)
                          </div>
                          <div className=" border-b-[1px] border-mint  pb-4 pt-2 md:text-base text-xs">
                            12.3Ω (+/- 0.5Ω)
                          </div>
                          <div className=" border-b-[1px] border-mint  pb-4 pt-2 md:text-base text-xs">
                            288Ω (+/- 5Ω)
                          </div>
                          <div className=" border-b-[1px] border-mint  pb-4 pt-2 md:text-base text-xs">
                            Bias current tolerance
                          </div>
                          <div className=" border-b-[1px] border-mint  pb-4 pt-2 md:text-base text-xs">
                            0.2mV (+/- 0.1mV)
                          </div>
                          <div className=" border-b-[1px] border-mint  pb-4 pt-2 md:text-base text-xs">
                            0.2mV (+/- 0.1mV)
                          </div>
                          <div className=" border-b-[1px] border-mint  pb-4 pt-2 md:text-base text-xs">
                            0.3mV (+/- 0.1mV)
                          </div>
                          <div className="   pb-4 pt-2 md:text-base text-xs">
                            Combined offset instability and internal noise
                          </div>
                          <div className="   pb-4 pt-2 md:text-base text-xs">
                            <div>42.9uvpp</div>
                            <div>(+/- 5uvpp)</div>
                          </div>
                          <div className="   pb-4 pt-2 md:text-base text-xs">
                            {" "}
                            <div>49.3uvpp</div>
                            <div>(+/- 5uvpp)</div>
                          </div>
                          <div className="   pb-4 pt-2 md:text-base text-xs">
                            {" "}
                            <div>142uvpp</div>
                            <div>(+/- 10uvpp)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </section>
        )}

        {shopdata.slug === "Writable-Development-Kit" ||
          shopdata.slug === "Sustainable-Development-Kit" ||
          shopdata.slug === "Data-Transmission-Development-kit"
          ? " "
          : shopdata.sectionTwo && (
            <section
              className={`${shopdata.slug === "flexible-battery" ? "hidden" : " block"
                }`}
            >
              <div className="bg-dusk">
                <div className="container mx-auto">
                  <div className="text-salmon mb-5">[2] Connectors</div>
                  <div className="text-mint text-sm mb-3 font-firs-medium">
                    User Cases: {shopdata.sectionTwo.userCases}
                  </div>
                  <div className="text-mint">
                    <>
                      <div className="grid grid-cols-2 gap-4 mb-4 font-firs-medium">
                        {shopdata.sectionTwo.tableTitles.map(
                          (item, index) => {
                            return <div className="">{item}</div>
                          }
                        )}
                      </div>
                      <div className="bg-mint pt-[1px] "></div>
                      {shopdata.sectionTwo.table.map((item, index) => {
                        return (
                          <>
                            <div className="grid grid-cols-2 gap-4 my-4">
                              <div>{item.row.title}</div>
                              <div>
                                {item.row.content.map((item, index) => {
                                  return <div className="mb-2">{item}</div>
                                })}
                              </div>
                            </div>
                            <div className="bg-mint pt-[1px] "></div>
                          </>
                        )
                      })}
                    </>
                  </div>
                </div>
              </div>
            </section>
          )}
        {shopdata.slug === "Writable-Development-Kit" ||
          shopdata.slug === "Sustainable-Development-Kit" ||
          shopdata.slug === "Data-Transmission-Development-kit"
          ? ""
          : shopdata.sectionThree && (
            <section
              className={`${shopdata.slug === "flexible-battery" ? "hidden" : " block"
                }`}
            >
              <div className="bg-dusk">
                <div className="container mx-auto py-20">
                  <div className="text-salmon mb-5">
                    [3] {shopdata.sectionThree.title}
                  </div>
                  <div className="text-mint text-sm mb-3">
                    Tech Specifications:
                  </div>
                  <div className="text-mint text-sm mb-10 max-w-xl">
                    {shopdata.sectionThree.techSpecification}
                  </div>
                  <div className="text-mint mb-3 font-firs-medium">
                    Wire specs:
                  </div>
                  <div className="grid grid-cols-4 gap-3 font-firs-medium text-mint mb-4">
                    {shopdata.sectionThree.tableTitles.map((item, index) => {
                      return <div className="">{item}</div>
                    })}
                  </div>
                  <div className="bg-mint pt-[1px]"></div>
                  <div className="grid grid-cols-4  text-mint mt-4 gap-3 ">
                    <div>
                      {shopdata.sectionThree.colomnOne.map((item, index) => {
                        return <div className="">{item}</div>
                      })}
                    </div>
                    <div>
                      {shopdata.sectionThree.colomnTwo.map((item, index) => {
                        return <div className="">{item}</div>
                      })}
                    </div>
                    <div>
                      {shopdata.sectionThree.colomnThree.map(
                        (item, index) => {
                          return <div className="">{item}</div>
                        }
                      )}
                    </div>
                    <div>
                      {shopdata.sectionThree.colomnFour.map((item, index) => {
                        return <div className="">{item}</div>
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

        <section>
          <div className="bg-dusk">
            <div className="container mx-auto pb-20">
              <div className="text-salmon mb-5">Operating Instructions</div>
              <a href={shopdata.download} download>
                <div className="text-mint text-sm ">
                  Download Instruction Manual, connection diagrams and code
                  samples{" "}
                </div>
              </a>
            </div>
          </div>
        </section>
        <section>
          <div className="bg-salmon">
            <div className="container mx-auto pb-20">
              <div className="text-center md:text-4xl  sm:text-4xl text-4xl lg:text-6xl mb-4 p-20 text-white">
                Explore Other Kits
              </div>
              <ShopSlider />
            </div>
          </div>
        </section>
        <section>
          <div className="flex lg:flex-row flex-col">
            <div className="lg:w-1/2 w-full">
              <BackgroundImage
                {...images.salmon_texture}
                className="h-full bg-cover lg:py-0 py-40"
              >
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    className="lg:w-auto w-20"
                    width={180}
                  />
                </div>
              </BackgroundImage>
            </div>
            <div className="lg:w-1/2 w-full">
              <div className="bg-chalk py-56 text-dusk px-5">
                <div className="text-center text-5xl sm:text-6xl mb-4 px-1">
                  Interested in partnering with Us?
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  Need more information or interested in selling Softmatter
                  Packs & Parts in your store? Please get in touch for more
                  information.
                </div>

                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-dusk  hover:bg-mint/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    dataJson(slug: { eq: $slug }) {
      sid
      slug
      download
      avatar {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      breadCrumb
      name
      price
      description
      components {
        title
        quantity
      }
      sectionOne {
        title
        techSpecification
      }
      sectionTwo {
        title
        userCases
        tableTitles
        table {
          row {
            title
            content
          }
        }
      }
      sectionThree {
        title
        techSpecification
        tableTitles
        colomnOne
        colomnTwo
        colomnThree
        colomnFour
      }
    }
    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
export default Shop
