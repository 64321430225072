import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "swiper/swiper-bundle.min.css"
SwiperCore.use([Navigation])
const ShopSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      slideImage1: file(
        relativePath: { eq: "toolkit/individual-pags/Data Transmission.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      slideImage2: file(
        relativePath: { eq: "toolkit/landingupdate/ThermalRegulationImage.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      slideImage3: file(
        relativePath: { eq: "toolkit/landingupdate/SensingImage.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      slideImage4: file(
        relativePath: {
          eq: "toolkit/individual-pags/wristable individual .png"
        }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      slideImage5: file(
        relativePath: { eq: "toolkit/individual-pags/sustainanbility .png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
      slideImage6: file(
        relativePath: { eq: "toolkit/individual-pags/Flexible_battery.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  const slideData = [
    {
      id: 1,
      title: "Textile Circuitry Toolkit",
      title2: "Explore >",
      image: getImage(data.slideImage1),
      url: "/shop/textile-circuitry-development-kit",
    },
    {
      id: 2,
      title: "Thermal Regulation  ToolKit",
      title2: "Explore >",
      image: getImage(data.slideImage2),
      url: "/shop/thermo-regulation-development-kit",
    },
    {
      id: 3,
      title: "Sensing Toolkit",
      title2: "Explore >",
      image: getImage(data.slideImage3),
      url: "/shop/sensing-development-kit",
    },
    {
      id: 4,
      title: "Materials Toolkit",
      title2: "Explore >",
      image: getImage(data.slideImage4),
      url: "/shop/materials-development-kit",
    },
    {
      id: 5,
      title: "Sustainable Toolkit",
      title2: "Explore >",
      image: getImage(data.slideImage5),
      url: "/shop/sustainable-development-kit",
    },
    {
      id: 6,
      title: "Flexible Battery",
      title2: "Explore >",
      image: getImage(data.slideImage6),
      url: "/shop/flexible-battery",
    },
  ]

  const swiperParams = {
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  return (
    <div className="max-w-screen-xl mx-auto">
      <Swiper
        {...swiperParams}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        speed={1000}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        loop={true}
        modules={[Pagination, Navigation, Autoplay]}
      >
        {slideData.map(slide => (
          <SwiperSlide key={slide.id}>
            <div className=" h-80 w-90 flex items-center justify-center">
              <GatsbyImage
                image={slide.image}
                alt={slide.title}
                className="max-h-full "
              />
            </div>
            <div className="md:text-lg sm:text-2xl text-2xl font-bold mt-4 text-dusk">
              {slide.title}
            </div>
            <Link
              to={slide.url}
              className="text-white text-base mt-4 cursor-pointer"
            >
              {slide.title2}
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default ShopSlider
